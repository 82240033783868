import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {
  @Input() consent: any;
  @Output() changed: EventEmitter<any> = new EventEmitter();

  constructor(private dataService: DataService) { }

  ngOnInit() {
  }

  changeConsent(state) {
    let consent = this.consent;
    if (consent.state != state) {
      consent.state = state;
      this.dataService.setConsent(consent.id, 'state', state);
      this.changed.emit(consent);
    }
  }

}
