import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from './notification.service';

const Null = new Proxy({}, {
  set: (ob, prop, value) => {
    return true;
  },
  get: (ob, prop) => {
    return this;
  }
});
Null.toString = () => {
  return "";
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private notificationItems: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private consentItems: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private sampleItems: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private studyItems: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private institutionItems: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private requestItems: BehaviorSubject<any[]> = new BehaviorSubject([]);

  private notifications = {};
  private consents = {};
  private samples = {};
  private studys = {};
  private institutions = {};
  private fields = {};
  private requests = {};

  constructor(private notificationService: NotificationService, http: Http) {
    // setting values
    http.get('/assets/data.json').toPromise().then(data => {
      this.notifications = data.json().notifications;
      this.consents = data.json().consents;
      this.samples = data.json().samples;
      this.studys = data.json().studys;
      this.institutions = data.json().institutions;
      this.fields = data.json().fields;
      this.requests = data.json().requests;
      // update subjects
      this.update('consents');
      this.update('samples');
      this.update('studys');
      this.update('institutions');
      this.update('requests');
      let notifications = this.update('notifications');
      for (let notification of notifications) {
        if (!notification.read) {
          this.notificationService.showNotification(notification);
        }
      }
    });
  }

  private resolveItem(item: {}, parent: string) {
    for (let key in item) {
      if (key.endsWith('_id')) {
        let id = item[key];
        let type = key.substr(0, key.length - 3);
        let subitem = this.resolveKey(item, parent, key, type, id);
        item[type] = subitem;
        delete item[key];
      } else if (key.endsWith('_ids')) {
        let ids = item[key];
        let type = key.substr(0, key.length - 4);
        let subitems = [];
        for (let id of ids) {
          let subitem = this.resolveKey(item, parent, key, type, id);
          subitems.push(subitem);
        }
        item[type + 's'] = subitems;
        delete item[key];
      } else if (key == 'date') {
        item[key] = new Date(Date.now()-Math.random()*100000000000);
      }
    }
  }
  private resolveKey(item: {}, parent: string, key: string, type: string, id: any) {
    if (id == null) {
      return Null;
    }
    let subitem = this[type + 's'][id];
    if ((parent + '_id') in subitem) {
      subitem[parent] = item;
      delete subitem[parent + '_id'];
    }
    this.resolveItem(subitem, type + 's');
    return subitem;
  }

  private update(type: string) {
    let items = this[type];
    let preparedItems = [];
    for (let key in items) {
      let item = items[key];
      this.resolveItem(item, type);
      preparedItems.push(item);
    }
    preparedItems.sort((a,b) => a.date < b.date ? 1 : a.date > b.date ? -1 : 0);
    this[type.substr(0,type.length-1) + 'Items'].next(preparedItems);
    return preparedItems;
  }

  public getNotifications() {
    return this.notificationItems.asObservable();
  }
  public getConsents() {
    return this.consentItems.asObservable();
  }
  public getSamples() {
    return this.sampleItems.asObservable();
  }
  public getStudies() {
    return this.studyItems.asObservable();
  }
  public getInstitutions() {
    return this.institutionItems.asObservable();
  }
  public getRequests() {
    return this.requestItems.asObservable();
  }

  public getNotification(id) {
    return this.notifications[id];
  }
  public setNotification(id, key, value) {
    this.notifications[id][key] = value;
    this.update('notifications');
  }

  public getConsent(id) {
    return this.consents[id];
  }
  public setConsent(id, key, value) {
    this.consents[id][key] = value;
  }
  public addConsent(consent) {
    let id = (this.hash(consent.subject_id + "|" + consent.institution_id + "|" + consent.study_id) + '').substr(1);
    consent.id = id;
    this.consents[id] = consent;
    console.log(consent);
    this.update('consents');
  }
  public removeConsent(id) {
    delete(this.consents[id]);
  }

  public setRequest(id, key, value) {
    this.requests[id][key] = value;
    this.update('requests');
  }

  // helpers
  private hash(s) {
    var hash = 0, i, chr;
    if (s.length === 0) return hash;
    for (i = 0; i < s.length; i++) {
      chr   = s.charCodeAt(i);
      hash  = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }
}
