import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Subscription } from 'rxjs';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: 'dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    { path: 'requests', title: 'Requests',  icon:'inbox', class: '' },
    { path: 'consents', title: 'Consents',  icon:'verified_user', class: '' },
    { path: 'studies', title: 'Studies',  icon:'timeline', class: '' },
    { path: 'institutions', title: 'Institutions',  icon:'account_balance', class: '' },
    { path: 'samples', title: 'Samples',  icon:'bubble_chart', class: '' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {
  notificationItems: any[];
  notificationSubscription: Subscription;
  requestItems: any[];
  requestSubscription: Subscription;
  menuItems: any[];

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.notificationSubscription = this.dataService.getNotifications().subscribe(items => {
      this.notificationItems = items.filter(item => !item.read);
    });
    this.requestSubscription = this.dataService.getRequests().subscribe(items => {
      this.requestItems = items.filter(item => !item.responded);
    });
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  ngOnDestroy() {
    this.notificationSubscription.unsubscribe();
    this.requestSubscription.unsubscribe();
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
